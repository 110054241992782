
.mainContainer {
    @apply bg-opacity-25 bg-white backdrop-blur-md rounded-md border border-gray-300 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6;
}

.leftSideContainer {
    @apply p-5 lg:p-10;
}

.leftSideContainer p {
    @apply text-gray-700;
}

.resumeButton {
    @apply mt-5 inline-flex font-light bg-black text-white text-xl py-3 px-5 rounded-xl items-center space-x-2 transition-all hover:bg-white hover:text-black duration-300 border-2 border-black;
}

.rightSideContainer {
    @apply p-0 border;
}