/* Navbar.module.css */

.navbar {
    @apply py-5 flex justify-between items-center;
}

.navbarFixed {
    @apply max-w-6xl mx-auto px-5 py-5 flex justify-between items-center fixed top-0 left-0 right-0 bg-white z-50;
}

.navbarLogoContainer {
    @apply flex items-center space-x-3;
}

.logo {
    @apply text-white w-11 h-11 rounded-full bg-black font-bold text-base flex justify-center items-center;
}

.logoText {
    @apply text-black font-bold font-raleway;
}

.navbarSocialIconsContainer {
    @apply flex space-x-2.5;
}

.letsConnectText {
    @apply flex text-center place-items-center font-bold font-raleway text-[0.0rem] sm:text-xs;
}

.iconSize {
    width: 40px !important;
    height: 40px !important;
}
