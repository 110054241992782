@media print {
    body, html {
        margin: 0;
        padding: 0;
        background-color: white !important;
    }

    .max-w-screen-md {
        max-width: none !important;
    }

    /* Adjust based on your needs for printing */
    .p-8 {
        padding: 1rem !important;
    }
}

/*!* Central container *!*/
/*.App {*/
/*  max-width: 1200px;*/
/*  margin: 0 auto;*/
/*  padding: 0 20px; !* Gives a bit of padding on smaller screens *!*/
/*}*/

/*!* Ensure all main sections have the same top and bottom margins *!*/
/*.App > div {*/
/*  margin-top: 0px;*/
/*  margin-bottom: 40px;*/
/*}*/

/*.name {*/
/*  position: relative;*/
/*  font-size: 24px;*/
/*  font-weight: bold;*/
/*  display: inline-block; !* Ensures the pseudo-element spans the width of the text *!*/
/*}*/

/*.name::after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -5px;*/
/*  left: 0;*/
/*  right: 0;*/
/*  height: 10px;*/
/*  width: 100%;*/
/*  background: url('assets/semicircle.svg') no-repeat center center;*/
/*  background-size: cover;*/
/*}*/


/*!* Alignment *!*/
/*.container p, .container h1, .container h2 {*/
/*  text-align: left;*/
/*}*/


/*!* Navbar styling *!*/
/*.navbar {*/
/*  padding: 20px 0;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*}*/

/*.header-text {*/
/*  margin-top: 40px; !* Increase the spacing *!*/
/*  font-size: 24px; !* A bit smaller *!*/
/*}*/

/*.main-title {*/
/*  margin-top: 60px; !* More space above the title *!*/
/*  font-size: 48px;*/
/*}*/

/*.subtitle {*/
/*  margin-top: 20px;*/
/*}*/

/*.see-resume-button {*/
/*  margin-top: 20px;*/
/*  margin-bottom: 40px; !* Space before the projects section *!*/
/*}*/


/*.logo {*/
/*  color: white;*/
/*  width: 45px;*/
/*  height: 45px;*/
/*  border-radius: 50%;*/
/*  background-color: black;*/
/*  font-size: 1rem;*/
/*  font-weight: bold;*/
/*  text-align: center;*/
/*  vertical-align: middle;*/
/*  justify-content: center;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.social-media {*/
/*  display: flex;*/
/*  gap: 10px;*/
/*}*/

/*.social-icon {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*  font-size: 1.2rem;*/
/*}*/

/*!* ... previous styles *!*/

/*.home-section {*/
/*  padding: 40px;*/
/*  text-align: left;*/
/*}*/

/*.greeting {*/
/*  font-size: 1.5rem;*/
/*  margin-bottom: 20px;*/
/*}*/

/*.headline {*/
/*  font-family: Raleway;*/
/*  font-size: 2.5rem;*/
/*  font-weight: bold;*/
/*  margin-bottom: 20px;*/
/*}*/

/*.availability {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.resume-btn {*/
/*  padding: 10px 20px;*/
/*  background-color: black;*/
/*  color: white;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  cursor: pointer;*/
/*  font-size: 1rem;*/
/*}*/

/*.projects-section {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  gap: 20px; !* Horizontal spacing between projects *!*/
/*}*/

/*.project-card {*/
/*  width: 100%;*/
/*  padding: 20px;*/
/*  background-color: #eee;*/
/*  border-radius: 10px;*/
/*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.footer {*/
/*  text-align: center;*/
/*  margin-top: 60px;*/

/*}*/
